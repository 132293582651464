import React from 'react'
import createdIcon from "../assests/images/CreatedIcon.gif"
import acceptStatus from "../assests/images/accepted-status.gif"
import rejectStatus from "../assests/images/rejected_status.gif"
import pendingStatus from "../assests/images/pending-status.gif"
import paymentExchange from "../assests/images/payment-exchange.gif"
import connectingArrow from "../assests/images/right-arrow.gif"
import connectingArrowStable from "../assests/images/right-arrow-stable.png"
import approvedBy from "../assests/images/approved.gif"
const VoucherTrackingPad = (props) => {
    const{statusLine,voucherData}=props
    return (
        <div className=" flex my-2 overflow-x-auto">
            <div className=" flex flex-col items-center border-2 w-[100px] border-blue-400 mx-1 rounded-md">
                <p className="text-white text-sm w-[100%] overflow-hidden overflow-ellipsis whitespace-nowrap bg-blue-500 px-2 text-center">
                    Created
                </p>
                <img src={createdIcon} className="w-[30px]" alt="Created Icon" />
                <p className="text-white text-sm w-[100%] overflow-hidden overflow-ellipsis whitespace-nowrap bg-blue-500 px-2 text-center">
                    {voucherData.user?.firstName +
                        " " +
                        voucherData.user?.lastName}
                </p>
            </div>{/*this will always denote to creator of voucher}*/}
            {statusLine.length > 0 && statusLine?.map((current, index) => {
                let status = statusLine[index]?.status
                return (
                    <>
                        {/* Check if the status is "Accepted" */}
                        {statusLine[index] && statusLine[index].status === "Pending" ? (
                            <img src={connectingArrow} className="w-[60px]" alt="Connecting Arrow" />
                        ) : (
                            <img src={connectingArrowStable} className="w-[60px]" alt="Stable Arrow" />
                        )}
                        <div className={`flex flex-col items-center border-2 w-[100px] h-[70px] ${status === "Rejected" && "bg-red-500"} ${status === "Rejected" && "border-red-500"} ${status === "Pending" && "bg-yellow-600"} ${status === "Pending" && "border-yellow-600"} ${status === "Accepted" && "bg-green-500"} ${status === "Accepted" && "border-green-500"} mx-1 rounded-md`}>



                            {statusLine[index] && statusLine[index].status === "Accepted" && <img src={acceptStatus} className="h-[47px] w-[100%]"></img>}
                            {statusLine[index] && statusLine[index].status === "Rejected" && <img src={rejectStatus} className="h-[47px] w-[100%]"></img>}
                            {statusLine[index] && statusLine[index].status === "Pending" && <img src={pendingStatus} className="h-[47px] w-[100%]"></img>}


                            <p className="text-white text-sm w-[100%] h-[23px] overflow-hidden overflow-ellipsis whitespace-nowrap px-2 text-center">
                                {current.handler}
                            </p>
                        </div>



                    </>
                );
            })}
            {(voucherData.statusType === "Accepted" && voucherData?.assignedName) && (
                <><img src={connectingArrow} className="w-[60px]" alt="Connecting Arrow" />
                    <div className=" flex flex-col items-center border-2  w-[100px] h-[70px] bg-purple-500 border-purple-500 mx-1 rounded-md">

                        <img src={pendingStatus} className="h-[47px] w-[100%]"></img>

                        <p className="text-white text-sm w-[100%] overflow-hidden overflow-ellipsis whitespace-nowrap px-2 text-center">
                            {voucherData?.assignedName}
                        </p>
                    </div>

                </>



            )}
            {voucherData.statusType === "Closed" && voucherData?.assignedTo && (
                <>
                    <img src={connectingArrowStable} className="w-[60px]" alt="Connecting Arrow" />
                    <div className=" flex flex-col items-center border-2  w-[100px] h-[70px] bg-green-500 border-green-500 mx-1 rounded-md">

                        <img src={paymentExchange} className="h-[47px] w-[100%]"></img>

                        <p className="text-white text-sm w-[100%] overflow-hidden overflow-ellipsis whitespace-nowrap px-2 text-center">
                            {voucherData?.assignedName}
                        </p>
                    </div>
                </>



            )}


        </div>
    )
}

export default VoucherTrackingPad
